import React, { lazy, Suspense, useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from '../configs/AppConfig';
import Login from '../components/auth/Login';
import { Row, Col, Card } from 'react-bootstrap';
import { FaBox, FaUserCircle } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { BiLogOut } from "react-icons/bi";
import LogoTipo from '../assets/img/logo-IPESA_1.png';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../src/redux/actions/Auth";
import axios from '../configs/axiosConfig';

const RouteInterceptor = ({ children, isAuthenticated, ...rest })  => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: AUTH_PREFIX_PATH,
                state: { from: location }
              }}
            />
          )
        }
      />
    );
}

const closeMenu = () => {
  let menu = document.querySelector('.navbar--movil');
  menu.classList.remove('navbar--movil--open');
  menu.classList.add('navbar--movil--close');
}

export const Views = ( props ) => {

    const { locale, token, location, signOut } = props;

    const singOutSession = async () => {
      let uid = localStorage.getItem('uid');
      let tokenMessaging = localStorage.getItem('tokenMessaging');
    
      if( tokenMessaging !== null )
      {
          let dataTokenMessaging = {
              "token" : tokenMessaging
          };

          await axios.post(`/rest/cerrar-sesion/${uid}`, dataTokenMessaging,
            {
              headers: {
                Authorization: `Bearer salir`,
              }
            }
          );
          // console.log( cerrarSesionToken );
      }
      signOut();
    }

    const isCheckedOption = ( value )  => {
      return props.location.pathname.toString().toLowerCase().includes(value);
    }

    useEffect(()=>{
    }, [])

    return (
        
        <Switch>
            <Route exact path="/">
                <Redirect to={APP_PREFIX_PATH} />
            </Route>
            
            <Route path={AUTH_PREFIX_PATH}>
                <Login/>
            </Route>

            {/* Aqui aun no esta el token, por lo que isAuthenicated es true, debe de ser la variable token */}
            <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
                
                <div className="container-fluid background--desktop">

                    <Row className="justify-content-xl-evenly justify-content-lg-evenly justify-content-md-evenly h-100">
                        <Col xl="3" lg="2" md="2" className="p-xl-4 p-lg-4 p-md-0 p-0 navbar--movil navbar--movil--close">

                            <div className="text-center mb-2 d-xl-block d-lg-none d-md-none d-sm-none d-none">
                              <Link className="text-decoration-none" to={`${APP_PREFIX_PATH}`}>
                                <img className="w-50" src={LogoTipo}/>
                              </Link>
                            </div>

                            <div className="background--movil--desktop shadow">

                              <div className="content--close--logo--movil" >
                                
                                <img src={LogoTipo}/>
                                
                                <IoIosClose className="close" onClick={ closeMenu }/>

                              </div>

                              <Link className="text-decoration-none" to={`${APP_PREFIX_PATH}/inventarios`}>
                                <Card className={ isCheckedOption("/inventarios") ? "text-center card--opciones--nav checked--desktop" : "text-center card--opciones--nav" } >
                                  <Card.Body>
                                    <FaBox/>
                                    <p className="text-uppercase">Inventarios</p>             
                                  </Card.Body>
                                </Card>
                              </Link>

                              <Link className="text-decoration-none" to={`${APP_PREFIX_PATH}/reportes/odoo-full`}>
                                <Card className={ isCheckedOption("/reportes/odoo-full") ? "text-center card--opciones--nav checked--desktop" : "text-center card--opciones--nav" } >
                                  <Card.Body>
                                    <FaBox/>
                                    <p className="text-uppercase">Inventario Odoo/Full</p>             
                                  </Card.Body>
                                </Card>
                              </Link>

                              <Card className="text-center card--opciones--nav" onClick={ () => singOutSession() }>
                                  <Card.Body>
                                    <BiLogOut/>
                                    <p className="text-uppercase">Cerrar sesión</p>             
                                  </Card.Body>
                                </Card>

                            </div>
                              
                        </Col>

                        <Col xl="9" lg="9" md="9" className="p-xl-4 p-lg-0 p-md-0 p-0 content--movil">

                            <div className="d-xl-flex d-lg-none d-md-none d-sm-none d-none justify-content-end align-items-center user--content--img">
                              <FaUserCircle/>
                            </div>
                            <div className="background--movil--app card--app shadow">
                                <Suspense fallback={
                                    <div className="d-flex justify-content-center align-items-center loading--content event--content">
                                        <div className="spinner-border"></div>    
                                    </div>
                                    }>

                                    <Switch>
                                        <Route path={`${APP_PREFIX_PATH}/example`} component={ lazy( () => import(`./app-views/example/index`) )} />
                                        { /* Inventarios */ }
                                        <Route path={`${APP_PREFIX_PATH}/reportes/odoo-full`} component={ lazy( () => import(`./app-views/historial/indexOdooFull`) ) }/>
                                        <Route path={`${APP_PREFIX_PATH}/reportes/cargar-odoo-full`} component={ lazy( () => import(`./app-views/historial/cargarOdooFull`) ) }/>
                                        <Route path={`${APP_PREFIX_PATH}/reportes/detalles/odoo-full/:idDoc`} component={ lazy( () => import(`./app-views/historial/detailsOdooFull`) ) }/>
                                        <Route path={`${APP_PREFIX_PATH}/inventarios/conteo/observacion/:idDoc`} component={ lazy( () => import(`./app-views/almacen/observacion`) ) }/>
                                        <Route path={`${APP_PREFIX_PATH}/inventarios/conteo/error/:idDoc`} component={ lazy( () => import(`./app-views/events/conteo-error`) ) }/>
                                        <Route path={`${APP_PREFIX_PATH}/inventarios/conteo/irregularidad/:idDoc`} component={ lazy( () => import(`./app-views/almacen/irregularidad`) ) }/>
                                        <Route path={`${APP_PREFIX_PATH}/inventarios/cargar/revision/:idDoc`} component={ lazy( () => import(`./app-views/historial/inventarioRevalidar`) ) }/>
                                        <Route path={`${APP_PREFIX_PATH}/inventarios/conteo/:idDoc`} component={ lazy( () => import(`./app-views/almacen/inventario`) )} />
                                        <Route path={`${APP_PREFIX_PATH}/inventarios/cargar`} component={ lazy( () => import(`./app-views/historial/cargarInventario`) ) }/>
                                        <Route path={`${APP_PREFIX_PATH}/inventarios/firma/:idDoc`} component={ lazy( () => import(`./app-views/almacen/firmaReporte`) ) }/>
                                        <Route path={`${APP_PREFIX_PATH}/inventarios/reporte-final/:idDoc`} component={ lazy( () => import(`./app-views/almacen/reporteCreado`) ) }/>
                                        <Route path={`${APP_PREFIX_PATH}/inventarios/detalles/:idDoc`} component={ lazy( () => import(`./app-views/historial/details`) ) }/>
                                        <Route path={`${APP_PREFIX_PATH}/inventarios/cancelar/:idDoc`} component={ lazy( () => import(`./app-views/historial/cancelar`) ) }/>
                                        <Route path={`${APP_PREFIX_PATH}/inventarios`} component={ lazy( () => import(`./app-views/historial/index`) )} />
                                        <Route path={`${APP_PREFIX_PATH}`} component={ lazy( () => import(`./app-views/home/index`) ) }/>
                                    </Switch>

                                </Suspense>
                            </div>
                        </Col>
                    </Row>

                </div>

            </RouteInterceptor>
        </Switch>
    )
}

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token }
};

export default withRouter(connect(mapStateToProps, { signOut })(Views));