import React from 'react';
import { CgSandClock } from "react-icons/cg";

const Loading = () => {
    return (
        <div className="d-flex justify-content-center align-items-center event--content">
            <CgSandClock/>
        </div>
    );
}

export default Loading;