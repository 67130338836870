import React from 'react';
import firebaseConfig from './configs/FirebaseConfig';
import { Provider } from 'react-redux';
import store from './redux/store'
import firebase from 'firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/styles.scss';
import Views from './views/index';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import like from './sounds/notificationSignature.wav';
import { ToastContainer, toast } from 'react-toastify';


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}else {
  firebase.app(); // if already initialized, use that one
}


/*const options = {
  // you can also just use 'bottom center'
  position: "top right",
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.FADE,
}*/

const likeAudio = new Audio(like);

// console.log(process.env.REACT_APP_ENV)
let safariAgent = false; 
if(navigator.userAgent.search("Safari") >= 0 & navigator.userAgent.search("Chrome") < 0)
{
  safariAgent = true
}

if(!safariAgent){
  const mensajeando = firebase.messaging();
  mensajeando.requestPermission()
  .then(function(){
    console.log('Have permissionn');
    return mensajeando.getToken({vapidKey: 'BDavtHuPcb0wx1SVE13oro5499RT3m69kxHxhumXegyYP0f66Kl-_ZcbgbkO4GTHFVhIdsZ75nQRtvKFnEISr3U'});
  })
  .then(function(token){
     // console.log('token firebase messagin: ',token);
    localStorage.setItem('tokenMessaging', token);
  })
  .catch(function(err){
    // console.log('Usted No quiere notificaciones');
    localStorage.setItem('tokenMessaging', null);
  })
  
  mensajeando.onMessage((payload) => {
    console.log('Message received. ', payload);
    likeAudio.play();
    toast.dark( "🎨" + payload.notification.title + " : " +payload.notification.body ,{autoClose: 4000 });
  // ...
  });
}

function App() {
  return (
    <div>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" component={Views}/>
          </Switch>
        </Router>
      </Provider>
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
      />
    </div>
  );
}

export default App;
