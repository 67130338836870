import { auth, googleAuthProvider, facebookAuthProvider } from '../components/auth/FirebaseAuth';
import axios from '../configs/axiosConfig';

const FirebaseService = {}

FirebaseService.signInEmailRequest = async (email, password) =>
	await auth.signInWithEmailAndPassword(email, password).then(user => user).catch(err => err);

FirebaseService.getToken = async ()=>{
  let tokenRefresh;
  await auth.onAuthStateChanged(async function(user) {
  if (user) {
    tokenRefresh = await getTokenRefresh(user);
    localStorage.setItem('fb_auth_token', tokenRefresh);
    axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('fb_auth_token')}`};
    console.log(axios.defaults.headers.common);
    return Promise.resolve(tokenRefresh);
  } else {
    // No user is signed in.
    console.log("no loggeado");
  }
  });
}

  async function getTokenRefresh(user){
    let token = await user.getIdToken(true);
    console.log("token: "+ token);
    return Promise.resolve(token);
  }
 // await auth.currentUser.getIdToken(true).then(idToken=>idToken).catch(err=>err);
		
FirebaseService.signOutRequest = async () =>
	await auth.signOut().then(user => user).catch(err => err);

FirebaseService.signInGoogleRequest = async () =>
  await auth.signInWithPopup(googleAuthProvider).then(user => user).catch(err => err);

FirebaseService.signInFacebookRequest = async () =>
  await auth.signInWithPopup(facebookAuthProvider).then(user => user).catch(err => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
	await auth.createUserWithEmailAndPassword(email, password).then(user => user).catch(err => err);	
	
export default FirebaseService