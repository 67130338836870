import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Button, Form, Row, Col, Card } from 'react-bootstrap';
import 'firebase/auth';
import { 
	signIn, 
    signUp,
	showLoading, 
	showAuthMessage, 
	hideAuthMessage
} from '../../redux/actions/Auth';
import LogoTipo from '../../assets/img/logo-IPESA_1.png';
import { motion } from "framer-motion"
import Alert from 'react-bootstrap/Alert'
import { CgDanger } from "react-icons/cg"
import { useHistory } from 'react-router-dom';
import { APP_PREFIX_PATH } from '../../configs/AppConfig';
import axios from '../../configs/axiosConfig';
import Loading from '../../views/app-views/events/loading';

export const Login = (props) => {

    const history = useHistory();
    const [verificando, setVerificando] = useState(false);
    const { 
		otherSignIn, 
		showForgetPassword, 
		hideAuthMessage,
		onForgetPasswordClick,
		showLoading,
		signInWithGoogle,
		signInWithFacebook,
		extra, 
		signIn, 
		token, 
		loading,
		redirect,
		showMessage,
		message,
		allowRedirect
	} = props

    useEffect(() => {
        console.log("token login: ",token)
        console.log("allow: ", allowRedirect)

		if ( token !== null && token !== undefined ) {
			showLoading();
            setVerificando(true);
            datosSesion(token);
		}
		if(showMessage) {

            setVerificando(false);

			setTimeout(() => {
				hideAuthMessage();
			}, 3000);
		}
	});

    const datosSesion = async ( _token ) => {

        try{
            let datosSesion = await axios.get(`/rest/datos-sesion/${_token}`);
            if( datosSesion.data.codigo === 200 )
            {
                localStorage.setItem('uid', datosSesion.data.objeto.idDoc);
                localStorage.setItem('rol', datosSesion.data.objeto.rol);
                localStorage.setItem('nombreUsuario', datosSesion.data.objeto.nombre);
                
                let tokenMessaging = localStorage.getItem('tokenMessaging');

                if( tokenMessaging !== null && tokenMessaging !== undefined )
                {
                    let dataTokenMessaging = {
                        "token" : tokenMessaging
                    };

                    await axios.post(`/rest/inserta-token/${datosSesion.data.objeto.idDoc}`, dataTokenMessaging);
                    // console.log( insertaToken );
                }

                history.push(redirect);
            }
        }catch(e){
            setVerificando(false);
        }
        
    }

    const onLogin = async (e) =>{
        e.preventDefault();
        setVerificando(true);
        const emailUsuario = e.target.usuario.value;
        const passwordUsuario = e.target.contraseña.value;
        const values = {
            'email': emailUsuario,
            'password': passwordUsuario
        }
        showLoading()
		signIn(values);
    }

    return (
        <>{ !loading ? (
        <div className="container-fluid background--color--principal content--login">
            <Row className="justify-content-center align-items-center h-100">
                <Col xl="5" lg="5" md="5" sm="5">
                    <div className="text-center mb-5">
                        <img className="logo--login" src={LogoTipo}/>
                    </div>
                    <Card>
                        <Card.Body>
                        <motion.div 
                            initial={{ opacity: 0, marginBottom: 0 }} 
                            animate={{ 
                                opacity: showMessage ? 1 : 0,
                                margin: showMessage ? 20 : 0,
                            }}> 
                            { showMessage ? <Alert variant="danger"><CgDanger/>{message}</Alert> : null
                            
                            }
                        </motion.div>
                            <Form onSubmit={onLogin} >
                                <h4 className="title">Iniciar sesión</h4>

                                <Form.Group controlId="formBasicEmail" className="mb-3">
                                    <Form.Label>Usuario</Form.Label>
                                    <Form.Control type="email" placeholder="" name="usuario"/>                            
                                </Form.Group> 
                            
                                <Form.Group controlId="formBasicPassword" className="mb-3">
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control type="password" placeholder="" name="contraseña"/>
                                </Form.Group>
                                
                                <Button className="btn--oscuro btn--login" type="submit" size="lg" style={ { width: "100%" } }
                                    disabled={verificando}
                                >
                                    Iniciar sesión
                                </Button>
                            </Form>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
            ) : (

                <div className="h-100 w-100 position-absolute">
                    <Loading/>
                </div>
            ) }
        </>
    );

}

const mapStateToProps = ({auth}) => {
	const {loading, message, showMessage, token, redirect} = auth;
  return {loading, message, showMessage, token, redirect}
}

const mapDispatchToProps = {
	signIn,
	showAuthMessage,
	showLoading,
	hideAuthMessage,
}

export default connect(mapStateToProps, mapDispatchToProps) (Login);