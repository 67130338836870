import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNCUSTOM,
	SIGNOUT,
    SIGNUP,
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
    signUpSuccess,
} from "../actions/Auth";

import FirebaseService from '../../services/FirebaseService'

export function* signInWithFBEmail() {
  	yield takeEvery(SIGNIN, function* ({payload}) {
		const {email, password} = payload;
		try {
			console.log("ME voy a loguear");
			const user = yield call(FirebaseService.signInEmailRequest, email, password);
			console.log("user: ", user)
			/*const token = yield call(FirebaseService.getToken);
			console.log("token: ", token);*/
			if (user.message) {
				yield put(showAuthMessage(user.message));
				console.log("no entro: ",user.message)
			} else {
				console.log("ya entro: ", user.user.uid)
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				localStorage.setItem('fb_auth_token', user.user.za);
				//localStorage.setItem('idEmpresa','idod');
				window.location.reload(true);
				yield put(authenticated(user.user.uid));
				console.log("autentificacion: ",authenticated(user.user.uid))
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signInWithCustom() {
	yield takeEvery(SIGNCUSTOM, function* ({payload}) {
	  const token = payload;
	  //console.log("custom token: ", token)
	  	try {
			yield call(FirebaseService.getToken, true);
		} catch (err) {
			console.log(err)
			yield put(showAuthMessage(err));
		}
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(FirebaseService.signOutRequest);
			if (signOutUser === undefined) {
				localStorage.removeItem(AUTH_TOKEN);
				localStorage.removeItem('fb_auth_token');
				localStorage.removeItem('uid');
				localStorage.removeItem('rol');
				localStorage.removeItem('nombreUsuario');
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(FirebaseService.signUpEmailRequest, email, password);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				yield put(signUpSuccess(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
}


export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithCustom)
  ]);
}
